import React, { useState } from 'react'
import { CFormInput } from '@coreui/react'
import PropTypes from 'prop-types'
import useCustomHook from 'src/customize/hooks/customHook'
import { PostData } from 'src/apiHandler'
import Dropzone from 'react-dropzone'

const UploadFile = ({
  label,
  fileTypes,
  formatSupported,
  media,
  callback,
  multiple,
  previewImage = true,
  apiUrl,
  requestKey,
  verticalPreview,
}) => {
  const { Toast, AppLoaderMain } = useCustomHook()
  const [currentFile, setCurrentFile] = useState('')
  const [files, setFiles] = useState([])
  const formData = {}

  const upload = (file) => {
    const data = new FormData()
    AppLoaderMain(true)
    if (requestKey) {
      data.append(requestKey, file)
    } else {
      data.append('image', file)
    }

    PostData(apiUrl ? apiUrl : 's3/upload-image', data, {
      contentType: 'multipart/form-data',
    }).then((response) => {
      AppLoaderMain(false)
      if (response.status === true) {
        const responseData = response.data
        const imgObject = {
          imageUrl: responseData.imageUrl,
          fileName: responseData.key,
        }
        if (apiUrl) {
          callback(response)
          return
        }
        callback(imgObject)
        if (multiple) {
          setFiles([...files, imgObject])
        } else {
          setCurrentFile(responseData.imageUrl)
        }
        Toast(response.message, 'success')
      } else {
        callback(response)
        Toast(response.message, 'error')
      }
    })
  }
  return (
    <>
      {label ? <label className="form-label">{label}</label> : null}
      {multiple && files.length ? (
        <div className="d-flex mb-3 gap-3">
          {files.map((file, k) => {
            return (
              <div key={k} style={{ width: '150px' }}>
                <div className="img-box-large">
                  <img src={file.imageUrl} alt="variant" />
                </div>
                <CFormInput
                  type="text"
                  onChange={(e) => {
                    const filesTemp = [...files]
                    filesTemp[k].alt = e.target.value
                    setFiles(filesTemp)
                  }}
                  //value={file.hoverImage.alt}
                  placeholder="Alt tag"
                  className="mt-2"
                />
              </div>
            )
          })}
        </div>
      ) : null}

      <Dropzone
        maxFiles={1}
        multiple={multiple ? multiple : false}
        onError={(e) => console.log(e)}
        accept={fileTypes}
        onDrop={(files) => upload(files[0])}
      >
        {({ getRootProps, getInputProps }) => (
          <section className={`image-container ${verticalPreview ? 'vertical-preview' : 'xsmall'}`}>
            <div
              {...getRootProps({
                className: formData['images'] ? 'dropzone image-uploaded' : 'dropzone',
              })}
            >
              {previewImage &&
                (media?.imageUrl || currentFile ? (
                  <div className="uploaded-file product mb-3">
                    <img src={currentFile || media.imageUrl} alt="field" />
                  </div>
                ) : null)}
              <div className="text-center">
                <img src="/assets/icons/upload.svg" alt="upload" />
                <input {...getInputProps()} />
                <div>Drag your file here or Browse</div>
                <div className="light">Supported Formats: {formatSupported}</div>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    </>
  )
}

UploadFile.propTypes = {
  label: PropTypes.string,
  fileTypes: PropTypes.object,
  formatSupported: PropTypes.string,
  callback: PropTypes.any,
  media: PropTypes.object,
  multiple: PropTypes.bool,
  previewImage: PropTypes.bool,
  apiUrl: PropTypes.string,
  requestKey: PropTypes.string,
  verticalPreview: PropTypes.bool,
}

export default UploadFile
