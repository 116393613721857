import { memo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { GetData } from './apiHandler'
import {
  HANDLE_NOTIFICATION,
  ORDER_COUNT,
  ENQUIRIES_COUNT,
  SET_COMMON_DATA,
  SET_COMMON_DEVICE_TYPE_DATA,
  SET_COMMON_STATUS,
} from './redux/actions/types'
import PropTypes from 'prop-types'

const GetCommonData = ({ setLoadComponent }) => {
  const dispatch = useDispatch()
  let token = localStorage.getItem('token')

  const getCommonData = useCallback(
    (token) => {
      GetData('common/common-data', token).then((response) => {
        if (response.status === true) {
          setLoadComponent(true)
          dispatch({ type: SET_COMMON_DATA, payload: response?.data })
          dispatch({ type: SET_COMMON_STATUS, payload: response?.data })
        }
      })
    },
    [dispatch, setLoadComponent],
  )

  const getCommonStatus = useCallback(() => {
    /*GetData('common/common-data').then((response) => {
      if (response.status === true) {
        setLoadComponent(true)
        dispatch({ type: SET_COMMON_STATUS, payload: response?.data })
      }
    })*/
  }, [dispatch, setLoadComponent])

  const getCommonDeviceTypeList = useCallback(() => {
    GetData('device-type/get-device-types').then((response) => {
      if (response.status === true) {
        setLoadComponent(true)
        dispatch({ type: SET_COMMON_DEVICE_TYPE_DATA, payload: response.data })
      }
    })
  }, [dispatch, setLoadComponent])

  const getOrderCount = useCallback(() => {
    GetData('order/new-order-count').then((response) => {
      if (response.status === true) {
        setLoadComponent(true)
        dispatch({ type: ORDER_COUNT, payload: response.data })
      }
    })
  }, [dispatch, setLoadComponent])

  const getContactEnquiriesCount = useCallback(() => {
    GetData('form/count').then((response) => {
      if (response.status === true) {
        setLoadComponent(true)
        dispatch({ type: ENQUIRIES_COUNT, payload: response.data })
      }
    })
  }, [dispatch, setLoadComponent])

  const getNotifications = useCallback(() => {
    GetData('notification/notifications-list').then((response) => {
      if (response.status === true) {
        setLoadComponent(true)
        dispatch({ type: HANDLE_NOTIFICATION, payload: response.data })
      }
    })
  }, [dispatch, setLoadComponent])

  useEffect(() => {
    if (token) {
      getCommonData(localStorage.getItem('token'))
      getCommonStatus()
      getCommonDeviceTypeList()
      getOrderCount()
      getContactEnquiriesCount()
      getNotifications()
    }
  }, [
    getCommonData,
    getCommonDeviceTypeList,
    getCommonStatus,
    getOrderCount,
    getContactEnquiriesCount,
    getNotifications,
    token,
  ])
}

GetCommonData.propTypes = {
  setLoadComponent: PropTypes.func.isRequired,
}

export default memo(GetCommonData)
