import { useDispatch, useSelector } from 'react-redux'
import { LOADER_HIDE, LOADER_SHOW, TOAST_SHOW } from '../../redux/actions/types'
import printJS from 'print-js'
import { useCallback } from 'react'
import { PostData } from 'src/apiHandler'

const useCustomHook = () => {
  const { commonAttributes } = useSelector((state) => state.appCommon)
  const dispatch = useDispatch()

  const getAttributeName = useCallback(
    (nameId, key = 'name', attributes = []) => {
      return [...attributes, ...commonAttributes]?.filter((item) => {
        return item?.id === parseInt(nameId)
      })[0]?.[key]
    },
    [commonAttributes],
  )

  const getAttributeValueName = useCallback(
    (attributeId, valueId, customAttributes = []) => {
      return getAttributeName(attributeId, 'value', customAttributes)?.filter((item) => {
        return item?.id === parseInt(valueId)
      })[0]?.name
    },
    [getAttributeName],
  )

  // incase of success no need to send type
  // incase of error send 'error'
  const Toast = useCallback(
    (message, type = 'success') => {
      return dispatch({
        type: TOAST_SHOW,
        payload: { message: message, [type]: true },
      })
    },
    [dispatch],
  )

  const AppLoaderMain = useCallback(
    (show) => {
      return dispatch({ type: show ? LOADER_SHOW : LOADER_HIDE })
    },
    [dispatch],
  )

  const printDocument = useCallback((URL, isBase64 = false, type = 'pdf', style) => {
    printJS({
      printable: URL,
      type,
      base64: isBase64,
      style: style,
    })
  }, [])

  const downloadDocument = useCallback((URL) => {
    const a = document.createElement('a')
    a.href = URL
    a.download = 'download'
    a.style.display = 'hidden'
    a.style.position = 'absolute'
    a.click()
  }, [])

  const copyToClipBoard = useCallback((text, successText) => {
    const textarea = document.createElement('textarea')
    textarea.value = text
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
    Toast(successText ?? 'Copied to Clipboard!')
  })

  const validateZipCode = useCallback(async (countryCode, zipCode) => {
    if (countryCode.length > 0 && zipCode.length === 5)
      try {
        let modifiedFormValue = {
          countryCode: countryCode,
          postalCode: zipCode,
        }
        const response = await PostData('customer/verify-area-code', modifiedFormValue)
        if (response.status === true) {
          return response.data.status
        } else {
          Toast(response.message, 'error')
          return false
        }
      } catch (error) {
        Toast('An error occurred while validating zip code', 'error')
        return false
      }
  }, [])

  return {
    getAttributeName,
    getAttributeValueName,
    Toast,
    AppLoaderMain,
    printDocument,
    downloadDocument,
    copyToClipBoard,
    validateZipCode,
  }
}

export default useCustomHook
