export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const SOCKET_CALLBACK = 'SOCKET_CALLBACK'
export const LOGOUT = 'LOGOUT'

export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS'

export const LOADER_SHOW = 'LOADER_SHOW'
export const LOADER_HIDE = 'LOADER_HIDE'
export const LOADER_TOGGLE = 'LOADER_TOGGLE'
export const SET_SIDEBAR = 'SET_SIDEBAR'
export const MANAGE_HEADER = 'MANAGE_HEADER'
export const TOAST_SHOW = 'TOAST_SHOW'
export const TOAST_HIDE = 'TOAST_HIDE'

export const SHOW_CONFIRMATION_MODAL = 'SHOW_CONFIRMATION_MODAL'
export const HIDE_CONFIRMATION_MODAL = 'HIDE_CONFIRMATION_MODAL'
export const SUBMIT_CONFIRMATION = 'SUBMIT_CONFIRMATION'
export const SHOW_LEAVE_WARNING_MODAL = 'SHOW_LEAVE_WARNING_MODAL'
export const HIDE_LEAVE_WARNING_MODAL = 'HIDE_LEAVE_WARNING_MODAL'

export const SHOW_GALLERY_MODAL = 'SHOW_GALLERY_MODAL'
export const HIDE_GALLERY_MODAL = 'HIDE_GALLERY_MODAL'

export const ORDER_COUNT = 'ORDER_COUNT'
export const ENQUIRIES_COUNT = 'ENQUIRIES_COUNT'
export const HANDLE_NOTIFICATION = 'HANDLE_NOTIFICATION'

export const SET_COMMON_DATA = 'SET_COMMON_DATA'
export const SET_COMMON_STATUS = 'SET_COMMON_STATUS'
export const SET_COMMON_DEVICE_TYPE_DATA = 'SET_COMMON_DEVICE_TYPE_DATA'
export const SET_COMMON_CUSTOMER_TAG = 'SET_COMMON_CUSTOMER_TAG'

export const VALIDATE_ADD_ORDER = 'VALIDATE_ADD_ORDER'

export const CSV_IMPORT_OPEN_MODAL = 'CSV_IMPORT_OPEN_MODAL'
export const CSV_IMPORT_CLOSE_MODAL = 'CSV_IMPORT_CLOSE_MODAL'
export const CSV_IMPORT_SET_FILES = 'CSV_IMPORT_SET_FILES'
export const CSV_IMPORT_SUBMIT = 'CSV_IMPORT_SUBMIT'
