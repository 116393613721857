import axios from 'axios'

const getApiUrl = (url) => {
  if (url.includes('v1')) {
    return url
  }
  return 'v2/admin/' + url
}

const getIpAddress = async () => {
  return await fetch('https://api.ipify.org?format=json')
    .then((response) => {
      return response.json()
    })
    .then((res) => {
      return res?.ip ? res.ip : ''
    })
    .catch((err) => console.error('Problem fetching my IP', err))
}

export const GetData = async (url, token = '') => {
  const headersData = {
    'Content-Type': 'application/json',
  }
  if (localStorage.token || token) {
    headersData.Authorization = (token || localStorage.token).slice(7)
  }
  return await axios
    .get(process.env.REACT_APP_API_URL + getApiUrl(url), {
      headers: headersData,
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (error?.response?.status === 401 && window.location.pathname !== '/') {
        setTimeout(() => {
          localStorage.clear()
          window.location.href = '/'
        }, 1000)
      }
      if (error?.response?.status === 404) {
        return {
          success: false,
          message:
            error?.response?.data?.message || error.message
              ? error?.response?.data?.message || error.message
              : '404 api not found..',
        }
      }
      if (error?.response?.data) {
        return error?.response?.data
      } else {
        return { success: false, message: 'Internal server error.' }
      }
    })
}

export const DeleteData = async (url, data, token = '') => {
  const headersData = {
    'Content-Type': 'application/json',
  }
  if (localStorage.token || token) {
    headersData.Authorization = (token || localStorage.token).slice(7)
  }
  return await axios
    .delete(process.env.REACT_APP_API_URL + getApiUrl(url), {
      headers: headersData,
      data: data,
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      if (error?.response?.status === 401 && window.location.pathname !== '/') {
        setTimeout(() => {
          localStorage.clear()
          window.location.href = '/'
        }, 1000)
      }
      if (error?.response?.status === 404) {
        return { success: false, message: error.message ? error.message : '404 api not found..' }
      }
      if (error?.response?.status === 400) {
        return {
          success: false,
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : '400 Bad request..',
        }
      }
      if (error?.response?.data) {
        return error?.response?.data
      } else {
        return { success: false, message: 'Internal server error.' }
      }
    })
}

export const PostData = async (url, data, headers) => {
  const headersData = {
    'Content-Type': headers?.contentType ?? 'application/json',
  }
  if (headers?.token) {
    headersData.Authorization = headers.token
  } else {
    if (localStorage.token) {
      headersData.Authorization = localStorage.token.slice(7)
    }
  }
  if (headers?.type) {
    headersData.Type = headers.type
    headersData.Orderid = headers.orderId
  }

  if (url.split('/').includes('login')) {
    const ip = await getIpAddress()
    if (ip) {
      data['Ip'] = ip
    }
  }
  return await axios
    .post(process.env.REACT_APP_API_URL + getApiUrl(url), data, {
      headers: headersData,
    })
    .then(function (response) {
      if (getApiUrl(url).includes('login')) {
        localStorage.setItem('token', response.headers.authorization)
      }
      return response.data
    })
    .catch(function (error) {
      if (error?.response?.status === 401 && window.location.pathname !== '/') {
        /*  setTimeout(() => {
            localStorage.clear()
            window.location.href = '/'
        }, 1000) */
      }
      if (error?.response?.status === 404) {
        return {
          success: false,
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : 'Api not found',
        }
      }
      if (error?.response?.data) {
        return error?.response?.data
      } else {
        return { success: false, message: 'Internal server error.' }
      }
    })
}

export const UpdateData = async (url, data) => {
  const headersData = {
    'Content-Type': 'application/json',
  }
  if (localStorage.token) {
    headersData.Authorization = localStorage.token.slice(7)
  }
  return await axios
    .put(process.env.REACT_APP_API_URL + getApiUrl(url), data, {
      headers: headersData,
    })
    .then(function (response) {
      if (url === 'v2/admin/user/login') {
        localStorage.setItem('token', response.headers.authorization)
      }
      return response.data
    })
    .catch(function (error) {
      if (error?.response?.status === 401 && window.location.pathname !== '/') {
        /*  setTimeout(() => {
            localStorage.clear()
            window.location.href = '/'
        }, 1000) */
      }
      if (error?.response?.status === 404) {
        return {
          success: false,
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : 'Api not found',
        }
      }
      if (error?.response?.data) {
        return error?.response?.data
      } else {
        return { success: false, message: 'Internal server error.' }
      }
    })
}

export const PatchData = async (url, data) => {
  const headersData = {
    'Content-Type': 'application/json',
  }
  if (localStorage.token) {
    headersData.Authorization = localStorage.token.slice(7)
  }
  return await axios
    .patch(process.env.REACT_APP_API_URL + getApiUrl(url), data, {
      headers: headersData,
    })
    .then(function (response) {
      if (url === 'v2/admin/user/login') {
        localStorage.setItem('token', response.headers.authorization)
      }
      return response.data
    })
    .catch(function (error) {
      if (error?.response?.status === 401 && window.location.pathname !== '/') {
        /*  setTimeout(() => {
            localStorage.clear()
            window.location.href = '/'
        }, 1000) */
      }
      if (error?.response?.status === 404) {
        return {
          success: false,
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : 'Api not found',
        }
      }
      if (error?.response?.data) {
        return error?.response?.data
      } else {
        return { success: false, message: 'Internal server error.' }
      }
    })
}
