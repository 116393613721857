import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import io from 'socket.io-client'
import {
  HANDLE_NOTIFICATION,
  ORDER_COUNT,
  SOCKET_CALLBACK,
  ENQUIRIES_COUNT,
} from 'src/redux/actions/types'

const Socket = () => {
  const dispatch = useDispatch()

  const mainFlow = useCallback(
    (type, data) => {
      switch (type) {
        case 'totalCount':
          dispatch({ type: ORDER_COUNT, payload: data })
          break
        case 'notifications':
          let modifiedData = { ...data, createdAt: data?.date }
          delete modifiedData.date
          dispatch({ type: HANDLE_NOTIFICATION, payload: { data: [modifiedData] } })
          break
        case 'contactUsUnreadCount':
          dispatch({ type: ENQUIRIES_COUNT, payload: { count: data.unreadCount } })
          break
        default:
          dispatch({ type: SOCKET_CALLBACK, payload: { data, type } })
      }
    },
    [dispatch],
  )

  useEffect(() => {
    // Connect to the WebSocket server

    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      transports: ['websocket'], // Specify WebSocket as the transport
      upgrade: false, // Prevents the server from using other transports besides WebSocket
      // Additional options if needed
    })

    // Event listeners for various WebSocket events
    socket.on('connect', () => {
      console.log('Connected to WebSocket')
      // Perform actions upon successful connection
    })

    socket.onAny((type, info) => {
      mainFlow(type, info)
    })

    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket')
      // Handle disconnection
    })

    socket.on('error', (error) => {
      console.error('WebSocket error:', error)
      // Handle WebSocket errors
    })

    // Clean up WebSocket connection when the component unmounts
    return () => {
      socket.disconnect()
    }
  }, [dispatch, mainFlow])

  return null
}

export default Socket
