import React from 'react'
import { CModal, CModalBody, CModalTitle, CModalHeader } from '@coreui/react'
import { HIDE_GALLERY_MODAL } from 'src/redux/actions/types'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import UploadFile from '../UploadFile'
import { UpdateData } from 'src/apiHandler'
import useCustomHook from 'src/customize/hooks/customHook'

const GalleryModal = ({ visible, heading = 'Image Gallery', galleryData, data }) => {
  const dispatch = useDispatch()
  const { Toast, AppLoaderMain } = useCustomHook()
  const onClose = () => {
    dispatch({ type: HIDE_GALLERY_MODAL })
  }

  const uploadVariantMedia = (file) => {
    if (data?.variantId) {
      const formData = {
        type: 'Add',
        images: [file],
        variantIds: [data.variantId],
      }
      AppLoaderMain(true)
      UpdateData('pricing/update-bulk-images', formData).then(({ status, message }) => {
        AppLoaderMain(false)
        if (status) {
          dispatch({ type: HIDE_GALLERY_MODAL, payload: { reloadVariants: true } })
        } else {
          Toast(message, false)
        }
      })
    }
  }

  return (
    <CModal
      backdrop="static"
      visible={visible}
      size="sm"
      alignment="center"
      className="confirmation-modal"
      onClose={() => onClose()}
    >
      <CModalHeader className="border-none">
        <CModalTitle>{heading}</CModalTitle>
      </CModalHeader>
      <CModalBody className="center-v-h flex-direction-column">
        {data?.variantId ? (
          <div className="bulk-body w-100">
            <UploadFile
              label="Upload Image"
              fileTypes={{ 'image/*': [] }}
              media={{}}
              previewImage={false}
              callback={(e) => {
                uploadVariantMedia(e)
                console.log('e', e)
              }}
              formatSupported={'JPEG, PNG, JPG, WEBP'}
            />
          </div>
        ) : null}
        <ul className="gallery-images mt-4">
          {galleryData.map((image, k) => {
            return (
              <li key={k}>
                <img src={image.imageUrl} alt="gimg" />
              </li>
            )
          })}
        </ul>
      </CModalBody>
    </CModal>
  )
}

GalleryModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  heading: PropTypes.string,
  galleryData: PropTypes.array,
  data: PropTypes.object,
}

export default GalleryModal
